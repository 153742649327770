.global-filter-panel {
  display: flex;
  width: 100%;
  padding: 10px;
}

.grid-panel {
  display: flex;
  height: 75vh;
  width: 100%;
}